import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Modal from "../../../components/Modals";
import Input from "../../../components/Input/Input";
import InputMasked from "../../../components/Input/InputMasked";
import requests from "../../../services/requests";
import { VscTrash } from "react-icons/vsc";
import { navigate } from "gatsby";

const yup = require("yup");

const ModalDriverRegister = ({ actionModal, openModal, tab, handleSuccess }) => {
    const [newPicture, setNewPicture] = useState("");
    const [imageBlob, setImageBlob] = useState("");
    const [entity, setEntity] = useState({});
    const [step, setStep] = useState(1);

    function submitImage() {
        if (!newPicture) {
            return;
        }

        const data = new FormData();
        data.append("image_upload[file]", newPicture);
        requests
            .uploadUserPicture(entity?.identifier, data)
            .then((res) => {
                toast.success("Imagem atualizada com sucesso!")
                setNewPicture()
                navigate(`/gestao-de-motoristas/detalhes/${entity.driverId}`)
            })
            .catch(() => {
                toast.error("Imagem falhou, tente novamente.")
                setNewPicture()
            });
    }

    function handleInputImage(e) {
        const file = e.target.files[0];
        setNewPicture(file);
        setImageBlob(URL.createObjectURL(file));
    }

    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12 min-h-40%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5
                        className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}
                    >
                        Cadastro de motorista
                    </h5>
                </div>
            }
        >
            <div className="flex">
                <button
                    
                    onClick={() => setStep(1)}
                    className={` mr-4 cursor-pointer ${step === 1 ? "text-roxo_oficial" : "text-C6"
                        }`}
                >
                    Dados cadastrais
                </button>
                {entity.profilePictureUri && (
                    <button
                        
                        onClick={() => setStep(2)}
                        className={` mr-4 cursor-pointer ${step === 2 ? "text-roxo_oficial" : "text-C6"
                            }`}
                    >
                        Imagem de perfil
                    </button>
                )}
            </div>
            {step === 1 && (
                <>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            name: "",
                            email: "",
                            cellphone: "",
                            phone: "",
                            password: "",
                        }}
                        validationSchema={yup.object().shape({
                            name: yup.string().required("Campo obrigatório"),
                            email: yup.string().required("Campo obrigatório").email("Deve ser um e-mail válido"),
                            password: yup
                                .string()
                                .required("Campo obrigatório")
                                .min(6, "A senha deve conter no mínimo 6 caracteres"),
                            cellphone: yup.string(),
                            phone: yup.string(),
                        })}
                        onSubmit={(values, { setFieldError, setSubmitting }) => {
                            setSubmitting(true);

                            requests
                                .addUser("driver", {
                                    name: values.name,
                                    email: values.email,
                                    cellphone: values.cellphone,
                                    phone: values.phone,
                                    password: values.password,
                                })
                                .then(response => {
                                    setSubmitting(false);
                                    toast.success("Usuário " + response.name + " cadastrado com sucesso!");
                                    setEntity(response);
                                    setStep(2);
                                })
                                .catch(error => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({ handleChange, handleBlur, handleSubmit, isSubmitting, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="mx-5 mt-5">
                                    <label htmlFor="name" className="text-roxo_oficial font-bold">
                                        Nome completo
                                    </label>
                                    <Input name="name" id="name" onChange={handleChange} value={values.name} />
                                    <ErrorMessage component="label" name="name" className="text-red font-light w-full" />

                                    <label htmlFor="cellphone" className="text-roxo_oficial font-bold mt-4">
                                        Celular <small>(opcional)</small>
                                    </label>
                                    <InputMasked
                                        name="cellphone"
                                        id="cellphone"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.cellphone}
                                        mask="(99) 99999-9999"
                                    />
                                    <ErrorMessage component="label" name="cellphone" className="text-red font-light w-full" />

                                    <label htmlFor="phone" className="text-roxo_oficial font-bold mt-4">
                                        Telefone <small>(opcional)</small>
                                    </label>
                                    <InputMasked
                                        name="phone"
                                        id="phone"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.phone}
                                        mask="(99) 9999-9999"
                                    />
                                    <ErrorMessage component="label" name="phone" className="text-red font-light w-full" />

                                    <label htmlFor="email" className="text-roxo_oficial font-bold mt-4">
                                        E-mail
                                    </label>
                                    <Input name="email" id="email" onChange={handleChange} value={values.email} />
                                    <ErrorMessage component="label" name="email" className="text-red font-light w-full" />

                                    <label htmlFor="password" className="text-roxo_oficial font-bold mt-4">
                                        Senha de acesso
                                    </label>
                                    <Input
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={handleChange}
                                        value={values.password}
                                    />
                                    <ErrorMessage component="label" name="password" className="text-red font-light w-full" />
                                </div>
                                <button
                                    className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <>
                                            Salvando <ClipLoader size={10} loading={isSubmitting} />
                                        </>
                                    ) : (
                                        <>Salvar</>
                                    )}
                                </button>
                            </form>
                        )}
                    </Formik>
                </>
            )}
            {step === 2 && (
                <>
                    <div className="flex flex-col items-center">
                        <p className="font-bold text-roxo_oficial mb-2">Imagem de perfil</p>
                        <div className="flex flex-col items-center bg-white z-50">
                            <label htmlFor="image">
                                {entity.profilePictureUri && (
                                    <img
                                        className="h-32 w-32 border border-roxo_oficial rounded-full flex flex-col justify-end items-center"
                                        src={
                                            newPicture
                                                ? URL.createObjectURL(newPicture)
                                                : process.env.REACT_APP_BASE_URL + entity.profilePictureUri
                                        }
                                        alt={entity.name}
                                    />
                                )}
                                <input
                                    id="image"
                                    type="file"
                                    accept=".png, .jpeg"
                                    onChange={handleInputImage}
                                    hidden={true}
                                />
                            </label>

                            {newPicture && (
                                <button className="mt-3" type="button" onClick={() => setNewPicture()}>
                                    <VscTrash color="red" />
                                </button>
                            )}
                        </div>
                        <legend className="mt-3 mb-10">
                            PNG ou JPEG com o mínimo de 200x200 px de resolução
                        </legend>
                    </div>
                    <div className="inline-block w-full mt-5">
                        <button
                            onClick={() => submitImage()}
                            className="mt-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right"
                        >
                            <span>Salvar</span>
                        </button>
                        <button
                            onClick={() => navigate(`/gestao-de-motoristas/detalhes/${entity.driverId}`)}
                            className="mt-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mr-5"
                        >
                            <span>Cadastrar depois</span>
                        </button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default ModalDriverRegister;
